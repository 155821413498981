<template>
  <b-card-normal>
    <template v-slot:body>
      <div class="d-flex justify-content-between">
        <div>
          <b-button variant="primary" class="rounded-0 mr-1" :to="{ name: 'ayar-menu' }">Menü</b-button>
          <b-button variant="primary" class="rounded-0 mr-1" :to="{ name: 'ayar-seo' }">Seo</b-button>
          <b-button variant="primary" class="rounded-0 mr-1" :to="{ name: 'ayar-dil' }">Dil</b-button>
          <b-button variant="primary" class="rounded-0 mr-1" :to="{ name: 'ayar-mail' }">Mail</b-button>
          <b-button variant="primary" class="rounded-0 mr-1" :to="{ name: 'ayar-sosyal-medya' }">Sosyal Medya</b-button>
          <b-button variant="primary" class="rounded-0 mr-1" :to="{ name: 'ayar-firma-bilgi' }">Firma Bilgileri</b-button>
          <b-button variant="primary" class="rounded-0 mr-1" :to="{ name: 'ayar-odeme' }">Ödeme Ayarları</b-button>
          <b-button variant="primary" class="rounded-0 mr-1" :to="{ name: 'ayar-diger' }">Diğer Ayarları</b-button>
        </div>
      </div>
    </template>
  </b-card-normal>
</template>

<script>
import BCardNormal from '@core/components/b-card-actions/BCardNormal.vue';
export default {
  components: {
    BCardNormal,
  },
  data() {
    return {};
  },
  watch: {
    async closeModal(val) {
      if (val == false) {
        this.sayfaOpenModal = false;
        this.aktiviteOpenModal = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
