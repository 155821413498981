<template>
  <div style="height: inherit">
    <menu-header />

    <transition mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
import MenuHeader from './component/header.vue';
export default {
  components: {
    MenuHeader,
  },
};
</script>

<style lang="scss" scoped></style>
